// Import styles
import 'normalize.css';
import 'bootstrap-4-grid';
import '@glidejs/glide/dist/css/glide.core.min.css';
import '../styles/main.scss';

// Import JS libraries
import Glide from '@glidejs/glide';
import noUiSlider from 'nouislider';
import wNumb from 'wnumb';
import SlimSelect from './vendor/slimselect.js';
import Pristine from 'pristinejs';
import { serializeJSON } from './parts/serialize.js';
import { parseVideo } from './parts/videoparser.js';
import MicroModal from 'micromodal';
import axios from 'axios';
import SimpleLightbox from './vendor/simpleLightbox.js';
import thumbnailYoutubeVimeo from 'thumbnail-youtube-vimeo';
import QueryString from 'querystring';

const addEventListenerMultiple = (element, eventNames, listener) => {
  let events = eventNames.split(' ');
  for (let i = 0, len = events.length; i < len; i++) {
    element.addEventListener(events[i], listener, false);
  }
}

// Init gallery
if (!!document.getElementById('real-estate-object-gallery')) {
  let controlsSelector = '#real-estate-object-gallery .gallery__frame .gallery__control';
  let slidesSelector = '#real-estate-object-gallery-slides .gallery__slide';
  let photoSelector = '#real-estate-object-gallery-photo';
  let gallerySlider = new Glide('#real-estate-object-gallery', {
    type: 'slider',
    perView: 5,
    peek: 20
  });

  gallerySlider.on(['mount.after', 'run'], () => {
    let slide = document.querySelectorAll(slidesSelector)[gallerySlider.index];
    let slideUrl = slide.querySelector('.gallery__thumb').getAttribute('data-image');
    let slideType = slide.querySelector('.gallery__thumb').getAttribute('data-image-type');
    let imageSign;
    
    switch(slideType) {
      case 'youtube':
        imageSign = `url('/assets/svg/icon_youtube_48.png'), `;
      break;
      case 'vimeo':
        imageSign = `url('/assets/svg/icon_vimeo_48.png'), `;
      break;
      case 'image':
        imageSign = `url(''), `;
      break;
      default:
        imageSign = ``;
      break;
    }
    
    document.querySelector(photoSelector).style.backgroundImage = `${imageSign}url('${slideUrl}')`;
    document.querySelector(photoSelector).setAttribute('data-index', gallerySlider.index);
  });

  gallerySlider.mount();

  document.querySelectorAll(controlsSelector).forEach(control => {
    addEventListenerMultiple(control, 'click touchstart', function(event) {
      gallerySlider.go(event.target.dataset.glideDir);
    });
  });

  document.querySelectorAll(slidesSelector).forEach((slide, index) => {
    const parse = parseVideo(slide.href);

    if (parse.embed !== undefined) {
      thumbnailYoutubeVimeo(slide.href, {
        ytimg: 'maxresdefault'
      })
        .then(result => {
          slide.innerHTML = `` +
            `<div` +
              ` class='gallery__thumb'` +
              ` style='background-image: url(${result})'` +
              ` data-image='${result}'` +
              ` data-image-type='${parse.type !== undefined ? parse.type : 'image'}'` +
            `>` +
            `</div>`;
        })
        .catch(error => {});
      slide.href = parse.embed;
    }

    addEventListenerMultiple(slide, 'click touchstart', function(event) {
      event.preventDefault();
      gallerySlider.go(`=${index}`);
    });
  });
  
  let galleryLightbox = new SimpleLightbox({
    elements: slidesSelector,
    preventListeners: true
  });

  document.querySelector(photoSelector).addEventListener('click', function(event) {
    galleryLightbox.showPosition(parseInt(event.target.getAttribute('data-index')));
  });
}

// Init slider
if (!!document.getElementById('best-objects-slider')) {
  var indexGlide;

  if (parseInt(window.innerWidth) > 991) {
    indexGlide = new Glide('#best-objects-slider', {
      type: 'carousel',
      perView: 3
    }).mount();
  } else {
    indexGlide = new Glide('#best-objects-slider', {
      type: 'carousel',
      perView: 1
    }).mount();
  }  

  indexGlide.on('resize', function() {
    if (parseInt(window.innerWidth) > 991) {
      indexGlide.update({ perView: 3 });
    } else {
      indexGlide.update({ perView: 1 });
    }
  });
}

// Init modals
MicroModal.init({
  awaitCloseAnimation: true
});

const modalObjectIdNode = document.querySelector('#modal-object-id');
const modalObjectHrefNode  = document.querySelector('#modal-object-href');

document.querySelectorAll('.real-estate-object__send-request').forEach(function(sender) {
  sender.addEventListener('click', function(event) {
    modalObjectIdNode.value = event.target.dataset.objectId;
    modalObjectHrefNode.href = event.target.dataset.objectHref;
    modalObjectHrefNode.textContent = event.target.dataset.objectTitle;
  })
});


// Init forms validation w/ Pristine
document.querySelectorAll('form').forEach(function(form) {
  const validator = new Pristine(form, {
    classTo: 'form-field',
    errorClass: 'form-field--error',
    successClass: 'form-field--success'
  });
  

    form.addEventListener('submit', function(event) {
      event.preventDefault();
      
      if (validator.validate()) {
        if (form.dataset.nativeProcess === undefined) {
          axios({
            method: form.dataset.method || form.method,
            url: form.action,
            data: QueryString.stringify(serializeJSON(form)),
            headers: {
              'X-Requested-With': 'XMLHttpRequest'
            }
          })
          .then(response => {
            switch (form.id) {
              case 'index-subscribe-to-objects-form':
                clearForm(form);
                alert('На вашу почту отправлено письмо на подтверждение рассылки');
              break;
              case 'index-leave-request-form':
                clearForm(form);
                alert('Вы оставили заявку');
              break;
              case 'modal-object-form':
                clearForm(form);
                alert('Вы оставили заявку на объект');
              break;
              case 'contacts-feedback-form':
                clearForm(form);
                alert('Ваше сообщение отправлено');
              break;
              default:
                alert('Default');
              break;
            }
          })
          .catch(error => {
            console.error(error);
          });
        } else {
          form.submit();
        }
      }
    });
});

const eventFire = (el, etype) => {
  if (el.fireEvent) {
    el.fireEvent('on' + etype);
  } else {
    let evObj = document.createEvent('Events');
    evObj.initEvent(etype, true, false);
    el.dispatchEvent(evObj);
  }
}

const clearForm = form => {
  form.querySelectorAll('input[type="text"], input[type="email"], textarea').forEach(input => {
    input.value = '';
  });
  form.querySelectorAll('input[type="checkbox"]').forEach(input => {
    input.checked = false;
  });
  // Very bad solution with click simulation
  form.querySelectorAll('.ss-value-delete').forEach(selectOptionDelete => {
    eventFire(selectOptionDelete, 'click'); 
  });
  form.querySelectorAll('option').forEach(option => {
    option.selected = false;
  });
  form.querySelectorAll('.form-field').forEach(field => {
    field.classList.remove('form-field--success');
    field.classList.remove('form-field--error');
  });
}

const inputToggleEmpty = input => {
  if (!!input.value) {
    input.classList.add('input--not-empty');
  } else {
    input.classList.remove('input--not-empty');
  }
}

const textareaResize = field => {
  // Reset field height
  field.style.height = 'inherit';
  
  // Get the computed styles for the element
  let computed = window.getComputedStyle(field);
  
  // Calculate the height
  let height = parseInt(computed.getPropertyValue('border-top-width'), 10)
    + parseInt(computed.getPropertyValue('padding-top'), 10)
    + field.scrollHeight
    + parseInt(computed.getPropertyValue('padding-bottom'), 10)
    + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

  field.style.height = height + 'px';
}

// Init text inputs
document.querySelectorAll('.js-input-text').forEach(function(inputText) {
  inputToggleEmpty(inputText);
  inputText.addEventListener('change', function(event) {
    inputToggleEmpty(event.target);
  });
});

// Init textareas
document.querySelectorAll('.js-input-textarea').forEach(function(inputTextarea) {
  inputToggleEmpty(inputTextarea);
  inputTextarea.addEventListener('change', function(event) {
    inputToggleEmpty(event.target);
  });
  inputTextarea.addEventListener('input', function(event) {
    textareaResize(event.target);
  });
});


// Init range inputs
document.querySelectorAll('.js-input-range').forEach(function(inputRange) {
  const inputs = [
    inputRange.querySelector('input[data-range="min"]'),
    inputRange.querySelector('input[data-range="max"]')
  ];
  const displays = [
    inputRange.querySelector('.js-input-range__min'),
    inputRange.querySelector('.js-input-range__max')
  ];
  const slider = noUiSlider.create(inputRange.querySelector('.js-input-range__slider'), {
    start: [parseFloat(inputs[0].value), parseFloat(inputs[1].value)],
    connect: [false, true, false],
    step: 100000,
    range: {
      min: parseFloat(inputRange.getAttribute('data-range-min')),
      max: parseFloat(inputRange.getAttribute('data-range-max'))
    },
    format: wNumb({
        decimals: 0,
        thousand: ' '
    })
  });

  slider.on('update', function(values, handle) {
    inputs[handle].value = parseFloat(values[handle].replace(/\s/g, ''));
    displays[handle].textContent = values[handle];
  });
});

// Init selects
document.querySelectorAll('.js-input-select').forEach(function(inputSelect) {
  new SlimSelect({
    select: inputSelect,
    showSearch: false,
    closeOnSelect: false
  });
});

// Init accordions
let accordion = document.getElementsByClassName('accordion');
for (let i = 0; i < accordion.length; i++) {
  accordion[i].addEventListener('click', function() {
    this.classList.toggle('active');
    let fold = this.nextElementSibling;
    if (fold.style.maxHeight) {
      fold.style.maxHeight = null;
    } else {
      fold.style.maxHeight = fold.scrollHeight + 'px';
    }
  });
}
